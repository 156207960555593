import React from 'react';
import Layout from '../components/Layout';

const NotFoundPage = () => (
    <Layout>
        <Layout>
            <div></div>
        </Layout>
    </Layout>
);

export default NotFoundPage;
